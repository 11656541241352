import React, {Component} from 'react';

class Logo extends Component {
    render(){
        
        return(
            <h1 className="logo"><span className="logoSpan">My </span>Virtual Cookbook</h1>
        );
    }
}

export default Logo;